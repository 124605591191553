<template>
  <Popover
    :value="calendarValuesFormat"
    popover-class=""
    class="calendar"
    readOnly
  >
    <div class="calendar-wrapper" v-on:click.stop>
      <div class="arrows">
        <button
          @click="moveMonth(-1)"
          :class="['arrow', { disabled: hideLeftArrow }]"
        >
          <img src="@/assets/arrow.svg?data" />
        </button>
        <button @click="moveMonth(1)" class="arrow right">
          <img src="@/assets/arrow.svg?data" />
        </button>
      </div>
      <CalendarRange
        v-model="calendarValues"
        :range-start="startDate"
        :range-end="endDate"
      ></CalendarRange>
    </div>
  </Popover>
</template>

<script>
import CalendarRange from './CalendarRange'
import Popover from '../Popover'
import { createFirstDayDate, addDays } from '@/utils'
import { defaultDateOffset } from '@/utils/const'

export default {
  name: 'Calendar',

  components: {
    CalendarRange,
    Popover,
  },

  inject: ['bus', 'setApiParams'],

  data() {
    return {
      calendarValues: undefined,
      hidden: true,
      startDate: createFirstDayDate(new Date()),
      hideLeftArrow: true,
    }
  },
  watch: {
    calendarValues({ start, end }) {
      const formatDate = date =>
        `${date.getFullYear()}-${
          date.getMonth() < 9 ? '0' : ''
        }${date.getMonth() + 1}-${
          date.getDate() < 10 ? '0' : ''
        }${date.getDate()}`
      this.setApiParams({
        pickup_date: formatDate(start),
        ...(end ? { dropoff_date: formatDate(end) } : {}),
      })
      if (!!start && !!end) {
        this.bus.$emit('openPopover')
        this.bus.$emit('filter:change')
      }
    },
  },
  computed: {
    endDate() {
      const now = new Date(this.startDate)
      now.setMonth(now.getMonth() + 1)
      return now
    },
    diffDays() {
      const { start, end } = this.calendarValues || {}
      if (!start || !end) return 1
      return parseInt((end.getTime() - start.getTime()) / 86400000)
    },
    calendarValuesFormat() {
      const { start, end } = this.calendarValues
      const locale = this.$t('monthsShort')
      if (!start && !end) return ''
      let out = `${start.getDate()}${
        !end || end.getMonth() !== start.getMonth()
          ? ` ${locale[start.getMonth()]}`
          : ''
      }${
        !end || end.getFullYear() !== start.getFullYear()
          ? ` ${start.getFullYear()}`
          : ''
      }`
      if (end)
        out += ` – ${end.getDate()} ${
          locale[end.getMonth()]
        } ${end.getFullYear()}`
      return out
    },
  },
  methods: {
    moveMonth(step) {
      if (step === -1 && this.hideLeftArrow) return
      const now = new Date()
      const date = new Date(this.startDate)
      date.setMonth(date.getMonth() + step)
      this.hideLeftArrow = now.getMonth() === date.getMonth()
      this.startDate = date
    },
  },
  provide() {
    return {
      diffDays: this.diffDays,
      calendarValues: this.calendarValues,
    }
  },
  created() {
    this.calendarValues = {
      start: addDays(new Date(), defaultDateOffset),
      end: addDays(new Date(), defaultDateOffset + 7),
    }
  },
}
</script>
