import { stringify } from '@/utils/qs'
import { apiUrl } from './const'

const checkStatus = response => {
  if (response.ok) {
    return response
  } else {
    const error = new Error(response.statusText)
    error.response = response
    return Promise.reject(error)
  }
}

export default Vue => {
  Vue.prototype.$api = function() {
    const { apikey, apisign } = this.$root.$options.propsData

    const get = (controller, { headers = {}, params, ...rest } = {}) => {
      const qs = stringify({
        signature: apisign,
        key: apikey,
        ...params,
      })
      return fetch(`${apiUrl}/${controller}${qs ? `?${qs}` : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        ...rest,
      })
        .then(checkStatus)
        .then(r => r.json())
        .catch(() => {
          console.error(`Error in fetch "/${controller}"`)
          return {}
        })
    }

    this.getCountriesList = id => {
      const countryId =
        id || (Object.freeze({ ...this.$root.$data.apiProps }) || {}).countryId
      return get(`countries/${countryId}`)
    }

    this.getCitiesList = id => {
      const countryId =
        id || (Object.freeze({ ...this.$root.$data.apiProps }) || {}).countryId
      return get(`cities/${countryId}`, {
        params: {
          countryId,
        },
      })
    }

    this.getCarList = () => {
      const {
        city_id,
        pickup_city_id,
        dropoff_city_id,
        pickup_date,
        dropoff_date,
        gearbox,
        offset,
        limit = 3,
      } = Object.freeze({ ...this.$root.$data.apiProps }) || {}
      return get('cars/search', {
        params: {
          'insurance[]': 2,
          city_id,
          pickup_city_id,
          dropoff_city_id: dropoff_city_id || pickup_city_id,
          pickup_date,
          dropoff_date,
          gearbox,
          offset,
          limit,
        },
      })
    }
    return this
  }
}
