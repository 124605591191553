<template>
  <div class="car-wrapper">
    <div v-if="!cars && !isLoading" class="not-found">
      {{ $t('carsNotFound') }}
    </div>
    <div v-if="isLoading" class="loading"></div>
    <div class="car-list" v-if="!isLoading && cars && cars.length">
      <div v-for="(car, index) in cars" :key="car.id" class="car-item">
        <p v-if="index === 0">{{ $t('forExample') }}</p>
        <a class="car-inner" :href="preparedUrl.car(car)" target="_blank">
          <div class="car-img">
            <img :src="car.image_url" alt="" />
          </div>
          <div class="car-title">
            {{ car.full_name.trim() }}
          </div>
          <div class="car-engine">
            {{ $t(`transmission[${car.transmission > 1 ? 'at' : 'mt'}]`) }},
            {{ $t(`fuel[${car.fuel_id}]`) }}
          </div>
          <div class="price-period">
            {{ (car.daily_cost || 0) | currency(chosenCountry) }}
          </div>
          <div class="day-price">
            {{ $t('perDay') }}
          </div>
        </a>
        <div class="item-shadow">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div
      v-if="!isLoading && cars && cars.length && carsCount > 0"
      class="and-more-cars"
    >
      <a :href="preparedUrl.search" target="_blank"
        >{{ $t('footer.and') }} {{ carsCount }} {{ $t('footer.more') }}</a
      >
    </div>
  </div>
</template>

<script>
import { currency } from '@/utils/const'
import get from 'lodash.get'

export default {
  name: 'Cars',
  inject: ['bus'],
  filters: {
    currency(val, chosenCountry) {
      return `${val} ${currency[chosenCountry.currency_code]}`
    },
  },
  props: {
    preparedUrl: {
      type: Object,
      default() {
        return {
          search: '',
          car: () => '',
        }
      },
    },
  },
  data() {
    return {
      isLoading: true,
      cars: undefined,
      carsCount: 0,
    }
  },
  computed: {
    chosenCountry() {
      return this.$root.$data.apiProps.country
    },
  },
  watch: {
    chosenCountry() {
      this.isLoading = true
    },
  },
  methods: {
    countCurrency(car) {
      return (
        (car.daily_cost || 0) * (car.days || 1) +
        get(car, 'delivery_cost[0]', 0)
      )
    },
    onFilterChange() {
      this.isLoading = true
      const {
        countryId,
        dropoff_city_id,
        pickup_city_id,
        dropoff_date,
        pickup_date,
      } = Object.freeze({ ...this.$root.$data.apiProps })

      if (
        countryId &&
        dropoff_city_id &&
        pickup_city_id &&
        dropoff_date &&
        pickup_date
      )
        this.$api()
          .getCarList()
          .then((r = {}) => {
            const rCars = Object.values(r.cars)
            const carsCount = rCars.reduce(
              (acc, cur) => acc + (cur.count || 0),
              0
            )
            const mappedCars = rCars
              .map(i => (i.cars || []).slice(0, 3))
              .filter(i => i.length)

            this.isLoading = false

            if (!mappedCars.length) return (this.cars = undefined)

            const cars = mappedCars.reduceRight((acc, cur, index) => {
              let sliceEnd = 2
              if (mappedCars.length > 2) {
                sliceEnd = 1
              }
              if (index === 1 && acc.length < 2) sliceEnd = 2
              if (index === 0) sliceEnd = 2
              if (index === 0 && acc.length < 3) sliceEnd = 3

              return [...cur.slice(0, sliceEnd), ...acc]
            }, [])

            this.cars = cars
            this.carsCount = carsCount - cars.length
          })
    },
  },
  created() {
    this.onFilterChange()
    this.bus.$on('filter:change', this.onFilterChange)
  },
  beforeDestroy() {
    this.bus.$off('filter:change', this.onFilterChange)
  },
}
</script>
