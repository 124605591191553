<template>
  <div class="popover-wrapper" @click.stop="isHidden ? show() : hide()">
    <div
      :class="['value', { 'with-arrow': showArrow, 'hand-cursor': readOnly }]"
    >
      {{ value }}
    </div>
    <div
      :class="['dropdown-arrow', { active: !isHidden }]"
      v-if="showArrow"
    ></div>
    <slot v-if="!isHidden"></slot>
  </div>
</template>

<script>
export default {
  name: 'Popover',
  data() {
    return {
      isHidden: true,
    }
  },
  inject: ['bus'],
  props: {
    value: String,
    placeholder: String,
    readOnly: {
      type: Boolean,
      default: true,
    },
    isLoading: Boolean,
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    show() {
      if (!this.isHidden || this.isLoading) return
      this.isHidden = false
      window.addEventListener('click', this.hide)
      this.bus.$emit('openPopover', this._uid)
    },
    hide() {
      this.isHidden = true
      window.removeEventListener('click', this.hide)
    },
  },
  mounted() {
    this.bus.$on('openPopover', uid => {
      if (this._uid !== uid) this.hide()
    })
  },

  beforeDestroy() {
    this.bus.$off('openPopover', this.hide)
  },
}
</script>
