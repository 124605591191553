<template>
  <Popover
    :placeholder="isLoading ? $t('loading') : $t('choose')"
    v-model="fieldModel"
    :isLoading="isLoading"
    @blur="onChangeModel"
  >
    <div class="place-picker-wrapper" v-on:click.stop v-if="!isLoading">
      <div class="pick-drop-switch">
        <span
          @click="switcher = 'pick'"
          :class="{ active: switcher === 'pick' }"
          >{{ $t('pickUp') }}</span
        >
        <span
          @click="switcher = 'drop'"
          :class="{ active: switcher === 'drop' }"
          >{{ $t('dropOff') }}</span
        >
      </div>
      <div class="place-list-wrapper">
        <div :class="['place-list', { 'place-dropoff': switcher === 'drop' }]">
          <ul>
            <li
              v-for="item in cities"
              @click="chooseListItem(item)"
              :key="item.id"
              :class="{
                active: item.id === (cityPick || {}).id,
              }"
            >
              <span :inner-html.prop="getName(item)"></span>
              <Airplane class="place-airplane" v-if="item.airport"></Airplane>
            </li>
          </ul>
          <ul>
            <li
              v-for="item in cities"
              @click="chooseListItem(item)"
              :key="item.id"
              :class="{
                active: item.id === (cityDrop || cityPick || {}).id,
              }"
            >
              <span :inner-html.prop="getName(item)"></span>
              <Airplane class="place-airplane" v-if="item.airport"></Airplane>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Popover>
</template>

<script>
import Popover from './Popover'
import Airplane from '@/assets/airplane.svg?inline'

import get from 'lodash.get'

export default {
  name: 'Place',
  components: {
    Popover,
    Airplane,
  },
  inject: ['bus', 'setApiParams'],
  props: {
    defaultCountry: Number,
    defaultCity: Number,
  },
  data() {
    return {
      countries: [],
      cities: [],
      fieldModel: '',
      selectedCountry: undefined,
      cityPick: undefined,
      cityDrop: undefined,
      isLoading: true,
      cityPickFilter: '',
      cityDropFilter: '',
      countryFilter: '',
      switcher: 'pick',
    }
  },
  watch: {
    cityPick(val) {
      this.onChangeModel(val)
    },
    cityDrop(val) {
      this.onChangeModel(val)
    },
    selectedCountry() {
      this.fieldModel = this.getName(this.selectedCountry)
      this.chooseCountry(this.selectedCountry.id)
    },
    defaultCountry(newVal) {
      this.changeSelectedCountry(newVal)
    },
    defaultCity(newVal) {
      if (!(this.cities || []).length) return
      this.cityPick = (this.cities.filter(i => i.id === newVal) || [])[0]
      if (this.cityDrop) this.cityDrop = this.cityPick
    },
    '$root.locale'() {
      this.onChangeModel(this.fieldModel, true)
    },
  },
  methods: {
    getName(obj) {
      const { locale } = this.$root
      return obj[`name${locale !== 'ru' ? '_en' : ''}`]
    },
    changeSelectedCountry(id) {
      this.$api()
        .getCountriesList(id)
        .then((r = {}) => {
          this.selectedCountry = r
        })
    },
    chooseCountry(id) {
      if (id) {
        this.isLoading = true
        this.setApiParams({
          countryId: id,
          country: this.selectedCountry,
        })
        this.$api()
          .getCitiesList()
          .then(cities => {
            this.isLoading = false
            this.cities = cities
            this.cityPick = this.defaultCity
              ? (cities.filter(i => i.id === this.defaultCity) || [])[0]
              : get(cities, '[0]', {})
          })
      } else {
        console.error(`Empty countryID in request`)
      }
    },
    chooseListItem(item) {
      const { switcher } = this
      switcher === 'pick' ? (this.cityPick = item) : (this.cityDrop = item)
      this.bus.$emit('openPopover')
    },
    onChangeModel(value, isBlur) {
      if (!value) return

      const { cityPick, cityDrop } = this

      const emitter = () => {
        const apiProps = { ...this.$root.$data.apiProps }
        if (
          isBlur ||
          (value.id === apiProps.pickup_city_id &&
            value.id ===
              (cityDrop ? apiProps.dropoff_city_id : apiProps.pickup_city_id))
        )
          return

        this.setApiParams({
          city_id: cityPick.id,
          pickup_city_id: cityPick.id,
          dropoff_city_id: cityDrop ? cityDrop.id : cityPick.id,
          cityPick,
          cityDrop: cityDrop || cityPick,
        })
        this.bus.$emit('filter:change')
      }

      const pickName = cityPick ? this.getName(cityPick) : ''
      const dropName = cityDrop ? this.getName(cityDrop) : ''
      this.fieldModel =
        !dropName || pickName === dropName
          ? pickName
          : `${pickName} – ${dropName}`

      if (value !== this.fieldModel && !this.isLoading) {
        emitter()
      }
    },
  },
  created() {
    this.changeSelectedCountry(this.defaultCountry)
  },
}
</script>
