'use strict'
const strictUriEncode = str =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    x =>
      `%${x
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  )

function encoderForArrayFormat(options) {
  switch (options.arrayFormat) {
    case 'index':
      return key => (result, value) => {
        const index = result.length
        if (value === undefined) {
          return result
        }

        if (value === null) {
          return [...result, [encode(key, options), '[', index, ']'].join('')]
        }

        return [
          ...result,
          [
            encode(key, options),
            '[',
            encode(index, options),
            ']=',
            encode(value, options),
          ].join(''),
        ]
      }

    case 'bracket':
      return key => (result, value) => {
        if (value === undefined) {
          return result
        }

        if (value === null) {
          return [...result, [encode(key, options), '[]'].join('')]
        }

        return [
          ...result,
          [encode(key, options), '[]=', encode(value, options)].join(''),
        ]
      }

    case 'comma':
      return key => (result, value, index) => {
        if (!value) {
          return result
        }

        if (index === 0) {
          return [[encode(key, options), '=', encode(value, options)].join('')]
        }

        return [[result, encode(value, options)].join(',')]
      }

    default:
      return key => (result, value) => {
        if (value === undefined) {
          return result
        }

        if (value === null) {
          return [...result, encode(key, options)]
        }

        return [
          ...result,
          [encode(key, options), '=', encode(value, options)].join(''),
        ]
      }
  }
}

function encode(value, options) {
  if (options.encode) {
    return options.strict ? strictUriEncode(value) : encodeURIComponent(value)
  }

  return value
}

export const stringify = (object, options) => {
  if (!object) {
    return ''
  }

  options = Object.assign(
    {
      encode: true,
      strict: true,
      arrayFormat: 'none',
    },
    options
  )

  const formatter = encoderForArrayFormat(options)
  const keys = Object.keys(object)

  if (options.sort !== false) {
    keys.sort(options.sort)
  }

  return keys
    .map(key => {
      const value = object[key]

      if (value === undefined) {
        return ''
      }

      if (value === null) {
        return encode(key, options)
      }

      if (Array.isArray(value)) {
        return value.reduce(formatter(key), []).join('&')
      }

      return encode(key, options) + '=' + encode(value, options)
    })
    .filter(x => x.length > 0)
    .join('&')
}
