<template>
  <div
    :class="[
      'month-date',
      {
        isSelected,
        isDisabled,
        isBetween,
        isCurrent,
      },
    ]"
    :style="styleObj"
    :data-time="time"
    @click="handleClick"
  >
    {{ date | formatDateName }}
  </div>
</template>

<script>
import { areDatesEqual, createDate } from '@/utils'

export default {
  name: 'BaseCalendarRangeMonthDate',

  filters: {
    formatDateName(date) {
      return date.getDate()
    },
  },

  props: {
    date: {
      type: Date,
      default() {
        return createDate()
      },
    },
    start: {
      type: Date,
      default: undefined,
    },
    end: {
      type: Date,
      default: undefined,
    },
    enter: {
      type: Date,
      default: undefined,
    },
    rangeStart: {
      type: Date,
      default: undefined,
    },
    rangeEnd: {
      type: Date,
      default: undefined,
    },
  },

  inject: ['bus'],

  computed: {
    isCurrent() {
      return areDatesEqual(this.date, createDate())
    },

    styleObj() {
      const day = this.date.getDay() === 0 ? 7 : this.date.getDay()

      return {
        ...(this.date.getDate() === 1 && {
          'margin-left': `${14.2 * (day - 1)}% !important`,
        }),
      }
    },

    isSelected() {
      return (
        areDatesEqual(this.date, this.start) ||
        areDatesEqual(this.date, this.end)
      )
    },

    isBetween() {
      let { start, end, enter, date, isSelected } = this

      if (!end && enter < start) {
        end = start
        start = enter
      }

      if (!end && enter >= start) {
        end = enter
      }

      return !isSelected && date > start && date < end
    },

    isDisabled() {
      return this.date < createDate()
    },

    time() {
      return this.date.getTime()
    },
  },

  methods: {
    handleClick() {
      if (!this.isDisabled) {
        this.bus.$emit('pick', this.date)
      }
    },
  },
}
</script>
