export function areDatesEqual(firstDate, secondDate) {
  if (firstDate instanceof Date && secondDate instanceof Date) {
    return createDate(firstDate).getTime() === createDate(secondDate).getTime()
  }

  return false
}
export function createDate(date = new Date()) {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return new Date(year, month, day, 0, 0, 0, 0)
}

export function createFirstDayDate(date = new Date()) {
  const d = createDate(date)
  d.setDate(1)

  return d
}

export function addDays(date = new Date(), days) {
  const d = createDate(date)
  d.setDate(d.getDate() + days)

  return d
}
