<template>
  <div class="calendar-range-inner">
    <slot
      v-for="(date, i) of months"
      name="month"
      :date="date"
      :index="i"
      :start="start"
      :end="end"
      :enter="enter"
      :range-start="computedRangeStart"
      :range-end="computedRangeEnd"
    ></slot>
  </div>
</template>

<script>
import { createDate } from '@/utils'

function makeDate(date) {
  return date instanceof Date ? createDate(date) : undefined
}

function createMonths(from = createDate(), to = createDate()) {
  const months = []
  from.setDate(1)

  while (from.valueOf() <= to.valueOf()) {
    const month = createDate(from)
    months.push(month)
    from.setMonth(from.getMonth() + 1)
  }

  return months
}

export default {
  name: 'BaseCalendarRange',

  filters: {
    formatMonthName(date) {
      return date.toLocaleDateString(undefined, {
        month: 'long',
        year: 'numeric',
      })
    },
  },

  inject: ['bus'],

  model: {
    prop: 'period',
    event: 'calendar:input',
  },

  props: {
    rangeStart: {
      type: Date,
      default: undefined,
    },
    rangeEnd: {
      type: Date,
      default: undefined,
    },
    period: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      start: makeDate(this.period.start),
      end: makeDate(this.period.end),
      enter: undefined,
    }
  },

  computed: {
    computedRangeStart() {
      return makeDate(this.rangeStart)
    },

    computedRangeEnd() {
      return makeDate(this.rangeEnd)
    },

    from() {
      const { from } = this.period
      let fromDate = new Date(this.computedRangeStart)

      if (!fromDate || isNaN(fromDate.getTime())) {
        fromDate = createDate()
        fromDate.setMonth(fromDate.getMonth() - 6)
      }

      if (from instanceof Date && !isNaN(from.getTime()) && from < fromDate) {
        fromDate = createDate(from)
        fromDate.setDate(1)
      }

      return fromDate
    },

    to() {
      const { to } = this.period
      let toDate = new Date(this.computedRangeEnd)

      if (!toDate || isNaN(toDate.getTime())) {
        toDate = createDate()
        toDate.setMonth(toDate.getMonth() + 6)
      }

      if (to instanceof Date && !isNaN(to.getTime()) && to > toDate) {
        toDate = createDate(to)
        toDate.setDate(1)
        toDate.setMonth(toDate.getMonth() + 1)
      }

      return toDate
    },

    months() {
      return createMonths(this.from, this.to)
    },
  },

  mounted() {
    this.bus.$on('pick', this.handleDatePick)
  },

  beforeDestroy() {
    this.bus.$off('pick', this.handleDatePick)
  },

  watch: {
    period() {
      const { start, end } = this.period
      this.start = start
      this.end = end
    },
  },

  methods: {
    handleDatePick(date) {
      switch (true) {
        case !this.start:
          this.start = date
          break

        case this.start instanceof Date && this.end instanceof Date:
          this.start = date
          this.end = undefined
          break

        case this.start instanceof Date && date > this.start:
          this.end = date
          break

        case this.start instanceof Date && date <= this.start:
          this.end = this.start
          this.start = date
      }

      this.$emit('calendar:input', {
        start: this.start,
        end: this.end,
      })
    },

    handleDateEnter(date) {
      this.enter = date
    },
  },
}
</script>
