<template>
  <div class="gearbox-wrapper">
    <p>{{ $t('gearboxText') }}</p>
    <div class="gearbox-list">
      <div
        v-for="item in gearboxArray"
        :class="['gearbox-item', { active: item.value === active }]"
      >
        <div @click="changeGearbox(item.value)">
          <span></span>{{ $t(item.title) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gearbox',
  inject: ['bus', 'setApiParams'],
  data() {
    return {
      gearboxArray: [
        { title: 'gearbox.any', value: null },
        { title: 'gearbox.auto', value: 2 },
        { title: 'gearbox.manual', value: 1 },
      ],
      active: null,
    }
  },
  methods: {
    changeGearbox(value) {
      this.active = value
      this.setApiParams({
        gearbox: value,
      })
      this.bus.$emit('filter:change')
    },
  },
}
</script>
