<template>
  <section class="range-month">
    <h3 class="range-month-header">
      <div class="month-title">
        {{ $t(`months[${date.getMonth()}]`) }}
        <span class="range-month-year" v-if="index">{{
          date.getFullYear()
        }}</span>
      </div>
    </h3>
    <div class="calendar-grid">
      <div v-for="day of $t('days')" class="calendar-weekday">
        {{ day }}
      </div>
      <slot v-for="date of dates" name="date" :date="date"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BaseCalendarRangeMonth',

  props: {
    date: {
      type: Date,
      default() {
        return new Date()
      },
    },
    index: Number,
  },

  computed: {
    dates() {
      const dates = []
      const month = new Date(this.date)
      const currentMonthIndex = month.getMonth()
      month.setDate(1)

      while (month.getMonth() === currentMonthIndex) {
        dates.push(new Date(month))
        month.setDate(month.getDate() + 1)
      }

      return dates
    },
  },
}
</script>
