import get from 'lodash.get'

class I18n {
  static messages = {
    ru: {
      find: 'найти',
      gearbox: {
        any: 'любая',
        auto: 'автомат',
        manual: 'механическая',
      },
      months: [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
      ],
      days: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      monthsShort: [
        'янв',
        'фев',
        'мар',
        'апр',
        'мая',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек',
      ],
      choose: 'Выберите',
      loading: 'Загрузка',
      carsNotFound:
        'По такому запросу свободных автомобилей уже нет. Попробуйте изменить даты или условия поиска. Тогда мы точно что-то найдем.',
      forExample: 'Например,',
      fuel: ['', 'бензин', 'дизель', 'гибрид', 'турбодизель'],
      transmission: {
        at: 'Автомат',
        mt: 'Механика',
      },
      perDay: 'в день',
      pickUp: 'Получение',
      dropOff: 'Возврат',
      and: 'и',
      gearboxText: 'Коробка передач',
      header: {
        long: 'Прокат автомобилей в',
        short: 'Аренда автомобилей',
      },
      footer: {
        and: 'и ещё',
        more: 'авто',
        text: '– аренда автомобилей на курортах, которую рекомендуют друзьям',
      },
    },
    en: {
      find: 'find',
      months: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      days: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
      monthsShort: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ],
      gearbox: {
        any: 'any',
        auto: 'automatic',
        manual: 'manual',
      },
      choose: 'Choose',
      loading: 'Loading',
      forExample: 'For example',
      carsNotFound:
        'Sorry, there are no cars available marching your search. Try changing the dates or search criteria. We will definitely find something that suits you.',
      fuel: ['', 'gas', 'diesel', 'hybrid', 'turbodiesel'],
      transmission: {
        at: 'Automatic',
        mt: 'Manual',
      },
      perDay: 'per day',
      pickUp: 'Pick up',
      dropOff: 'Drop off',
      and: 'and',
      gearboxText: 'Gearbox',
      header: {
        long: 'Car rental in',
        short: 'Car rent',
      },
      footer: {
        and: 'and',
        more: 'more cars',
        text: 'is a car rental at resorts, which is recommended to friends',
      },
    },
  }

  install(Vue) {
    Vue.prototype.$t = this.t
  }
  t(str) {
    return get(I18n.messages, `${this.$root.locale}.${str}`, '')
  }
}

export default new I18n()
