<template>
  <div :class="['find-button', { outside: isOutside }]">
    <a :href="preparedUrl" target="_blank">{{ $t('find') }}</a>
  </div>
</template>

<script>
export default {
  name: 'FindButton',
  props: {
    preparedUrl: {
      type: String,
      default: '',
    },
    isOutside: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
