<template>
  <div class="calendar-range">
    <base-calendar
      v-model="inputPeriod"
      :range-start="rangeStart"
      :range-end="rangeEnd"
    >
      <calendar-month
        slot="month"
        slot-scope="params"
        :date="params.date"
        :index="params.index"
      >
        <month-date
          slot="date"
          slot-scope="dateParams"
          class="range-date"
          :date="dateParams.date"
          :start="period.start"
          :end="period.end"
          :enter="params.enter"
          :range-start="rangeStart"
          :range-end="rangeEnd"
        >
        </month-date>
      </calendar-month>
    </base-calendar>
  </div>
</template>

<script>
import BaseCalendar from './components/BaseCalendarRange'
import CalendarMonth from './components/BaseCalendarRangeMonth'
import MonthDate from './components/BaseCalendarRangeMonthDate'

export default {
  name: 'CalendarRange',

  components: {
    BaseCalendar,
    CalendarMonth,
    MonthDate,
  },

  model: {
    prop: 'period',
    event: 'calendar:input',
  },

  props: {
    period: {
      type: Object,
      default() {
        return {}
      },
    },
    rangeStart: {
      type: Date,
      default: undefined,
    },
    rangeEnd: {
      type: Date,
      default: undefined,
    },
  },

  computed: {
    inputPeriod: {
      get() {
        return this.period
      },
      set(value) {
        this.$emit('calendar:input', value)
      },
    },
  },
}
</script>
