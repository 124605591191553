<template>
  <div
    :class="[customClassName, 'myrentacar-widget']"
    :id="[id]"
    v-if="affiliate && selectedCountry && apikey && apisign"
    :style="{
      maxWidth: `${width} !important`,
    }"
  >
    <div :class="['widget-inner', bgType, { 'no-border': !border }]">
      <div :class="['widget-content-wrapper']">
        <div class="widget-content">
          <header v-if="logo || header">
            <div v-if="header" class="header-text">
              {{ $t(`header[${chosenCountry ? 'long' : 'short'}]`) }}
              {{ countryNameInHeader }}
            </div>
            <div class="logo-wrapper" v-if="logo">
              <Logo :class="['logo', { 'not-locked-logo': header }]"></Logo>
              <LogoSmall class="logo-small" v-if="header"></LogoSmall>
            </div>
          </header>
          <div class="controls">
            <div>
              <Place :default-country="country" :default-city="city" />
            </div>
            <div>
              <Calendar />
            </div>
            <div>
              <FindButton :prepared-url="preparedUrl.search"></FindButton>
            </div>
          </div>
          <Gearbox v-if="gearbox"></Gearbox>
          <FindButton
            :is-outside="true"
            :prepared-url="preparedUrl.search"
          ></FindButton>
          <Cars v-if="cars" :prepared-url="preparedUrl"></Cars>
          <footer v-if="footer">
            <a href="https://myrentacar.com/" target="_blank">
              Myrentacar.com
            </a>
            {{ $t('footer.text') }}
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from './components/Calendar'
import Place from './components/Place'
import Gearbox from './components/Gearbox'
import FindButton from './components/FindButton'
import Cars from './components/Cars'
import Logo from './assets/logo.svg?inline'
import LogoSmall from './assets/shortlogo.svg?inline'
import Vue from 'vue'
import { stringify } from '@/utils/qs'

export default {
  name: 'app',
  components: {
    Calendar,
    Place,
    Gearbox,
    Logo,
    FindButton,
    Cars,
    LogoSmall,
  },
  props: {
    affiliate: {
      type: String,
      required: true,
    },
    apikey: {
      type: String,
      required: true,
    },
    apisign: {
      type: String,
      required: true,
    },
    country: {
      type: Number,
    },
    city: {
      type: Number,
    },
    background: {
      type: String,
      default: 'light',
    },
    classname: String,
    id: String,
    width: {
      type: String,
      default: '100%',
    },
    border: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    lang: String,
    gearbox: {
      type: Boolean,
      default: false,
    },
    cars: {
      type: Boolean,
      default: false,
    },
    marker: String,
  },
  watch: {
    lang(newVal) {
      this.$root.locale = newVal
    },
  },
  computed: {
    selectedCountry() {
      const value = this.country
      if (!isNaN(value)) return true
      console.error(`Check "country" property in widget tag`)
      return false
    },
    bgType() {
      switch (this.background) {
        case 'dark':
        case 'transparent':
        case 'light':
          return this.background
        default:
          return 'light'
      }
    },
    countryNameInHeader() {
      if (!this.chosenCountry || !this.$root) return null
      const { locale } = this.$root

      return locale === 'ru'
        ? this.chosenCountry.name_in
        : this.chosenCountry.name_en
    },
    chosenCountry() {
      return this.$root.$data.apiProps.country
    },
    cities() {
      const { cityPick, cityDrop } = this.$root.$data.apiProps
      if (!cityPick) return null
      return {
        cityPick,
        cityDrop,
      }
    },
    apiDates() {
      const { dropoff_date = '', pickup_date = '' } = this.$root.$data.apiProps

      return {
        pickup_date,
        dropoff_date,
      }
    },
    preparedUrl() {
      if (!this.chosenCountry || !this.cities)
        return { search: '', car: () => '' }

      const qs = {
        r: encodeURIComponent(this.affiliate),
        ...(this.marker && { marker: encodeURIComponent(this.marker) }),
        pc: this.cities.cityPick.name_en
          .toLowerCase()
          .replace(/[()]/g, '')
          .replace(/\s+/g, '_'),
        dc: this.cities.cityDrop.name_en
          .toLowerCase()
          .replace(/[()]/g, '')
          .replace(/\s+/g, '_'),
        ...this.apiDates,
      }
      return {
        search: `${this.chosenCountry.referal}?${stringify(qs)}`,
        car: car => {
          return `https://myrentacar.com${car.url}`
        },
      }
    },
  },
  data() {
    return {
      customClassName: this.classname,
      isDark: this.background === 'dark',
      bus: new Vue(),
    }
  },
  provide() {
    return {
      bus: this.bus,
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900');
</style>
