import Vue from 'vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import unfetch from './utils/unfetch'
import App from './App.vue'
import Api from './utils/api'
import i18n from './utils/i18n'

window.fetch = unfetch

Vue.use(vueCustomElement)
Vue.use(i18n)
Vue.use(Api)
window.mrcWidgetIds = []

Vue.customElement('mrc-widget', App, {
  connectedCallback() {
    const id =
      (this.attributes.id || {}).value ||
      `mrc_${Math.random()
        .toString(32)
        .slice(2, 8)}`
    window.mrcWidgetIds.push(id)

    this.setAttribute('id', id)

    const styles = require('!css-loader!postcss-loader!stylus-loader!./style/app.styl').toString()

    const compiledCSS = styles.toString().replace(/#ID/gim, `#${id}`)
    const styleEl = document.createElement('style')
    styleEl.setAttribute('type', 'text/css')
    styleEl.setAttribute('data-mrc-widget', 'text/css')
    styleEl.innerHTML = compiledCSS
    document.head.appendChild(styleEl)
  },

  beforeCreateVueInstance(RootComponentDefinition) {
    const lang = () => {
      switch (
        RootComponentDefinition.propsData.lang ||
        (
          (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          navigator.userLanguage
        ).split('-')[0]
      ) {
        case 'ru':
          return 'ru'
        default:
          return 'en'
      }
    }

    RootComponentDefinition.data = () => ({
      apiProps: {
        gearbox: null,
      },
      locale: lang(),
    })

    RootComponentDefinition.provide = () => ({
      setApiParams(obj) {
        const _apiProps = Object.freeze(this.$root.$data.apiProps)
        this.$root.$data.apiProps = {
          ..._apiProps,
          ...obj,
        }
      },
    })

    return RootComponentDefinition
  },
  vueInstanceCreatedCallback() {
    const widgetTagsLength = document.getElementsByTagName('mrc-widget').length
    if (window.mrcWidgetIds.length === widgetTagsLength) {
      require('./utils/eqcss')
    }
  },
})
